import React, { useState } from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Link } from 'gatsby'
import Logo from '../components/Logo'
import { Switch } from '@headlessui/react'
import { Transition } from '@headlessui/react'
import Sites from '../components/Sites'

const HubungiKami = () => {
  const [enabled, setEnabled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
  }
  return (
    <Layout>
      <Seo
        title={`Hubungi Kami Situs Tips Judi Online Indonesia Gubelinlab`}
        description={`Hubungi Kami Situs Tips Judi Online Indonesia Gubelinlab Kapan Saja Jika Anda Memerlukan Bantuan Seputar Tips Judi Online`}
        url={`https://gubelinlab.com/hubungi-kami/`}
        image={`https://gubelinlab.com/images/og_image.jpg`}
      />
      <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-8xl mx-auto">
          <svg
            className="absolute left-full transform -translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="grid-dotted"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <circle
                  cx="2"
                  cy="2"
                  r="2"
                  className="text-gray-200"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                />
              </pattern>
            </defs>
            <rect width="404" height="404" fill="url(#grid-dotted)" />
          </svg>
          <svg
            className="absolute right-full bottom-0 transform translate-x-3/4"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <rect width="404" height="404" fill="url(#grid-dotted)" />
          </svg>
          <div className="relative py-3 px-4">
            <div className="absolute inset-0 bg-gradient-to-r from-light-blue-500 to-blue-700 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-2 sm:rounded-3xl" />
            <div className="relative bg-white rounded-3xl ring-1 ring-gray-200 ring-opacity-50">
              <div className="absolute inset-0">
                <div className="absolute rounded-3xl inset-y-0 left-0 w-1/2 bg-gray-50" />
              </div>
              <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                  <div className="max-w-lg mx-auto">
                    <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                      Hubungi Kami
                    </h1>
                    <p className="mt-3 text-lg leading-6 text-gray-500">
                      Hubungi Kami Situs Tips Judi Online Indonesia Gubelinlab Kapan Saja Jika Anda
                      Memerlukan Bantuan Seputar Tips Judi Online
                    </p>
                    <dl className="mt-8 text-base text-gray-500">
                      <div>
                        <dt className="sr-only">Postal address</dt>
                        <dd>
                          <p>Jl. Kebon Jeruk</p>
                          <p>Jakarta, Indonesia 11530</p>
                        </dd>
                      </div>
                      <div className="mt-6">
                        <dt className="sr-only">Phone number</dt>
                        <dd className="flex">
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                            />
                          </svg>
                          <span className="ml-3">+62 (21) 123-4567</span>
                        </dd>
                      </div>
                      <div className="mt-3">
                        <dt className="sr-only">Email</dt>
                        <dd className="flex">
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                          </svg>
                          <span className="ml-3">gubelinlabb@gmail.com</span>
                        </dd>
                      </div>
                    </dl>
                    <p className="mt-6 text-base text-gray-500">
                      Mencari Tips Judi Online? {` `}
                      <Link to="/tips-judi-online/" className="font-medium text-gray-700 underline">
                        Lihat Semua Tips Judi Terbaru
                      </Link>
                      .
                    </p>
                  </div>
                </div>
                <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-16 lg:px-8 xl:pl-12">
                  <div className="max-w-lg mx-auto lg:max-w-none space-y-8">
                    <div>
                      <Logo htmlClass={`h-16 w-auto mx-auto`} textColor={`#000`} />
                      <p className="mt-3 text-xl text-center leading-6 text-gray-500">
                        {isOpen
                          ? 'Terima Kasih , Kami Akan Segera Menghubungi Anda Kembali!'
                          : 'Isi Form Dibawah Ini Dengan Lengkap Dan Benar, Layanan Pelangan Kami Akan Segera Menghubungi Anda Kembali'}
                      </p>
                    </div>
                    <Transition
                      show={!isOpen}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      {(ref) => (
                        <form
                          ref={ref}
                          onSubmit={handleSubmit}
                          method="POST"
                          className={`mt-6 grid grid-cols-1 gap-y-6 ${isOpen ? 'hidden' : ''}`}
                        >
                          <div>
                            <label htmlFor="name" className="sr-only">
                              Full name
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-rose-500 focus:border-rose-500 border-gray-300 rounded-md"
                              placeholder="Full name"
                            />
                          </div>
                          <div>
                            <label htmlFor="email" className="sr-only">
                              Email
                            </label>
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-rose-500 focus:border-rose-500 border-gray-300 rounded-md"
                              placeholder="Email"
                            />
                          </div>
                          <div>
                            <label htmlFor="message" className="sr-only">
                              Message
                            </label>
                            <textarea
                              id="message"
                              name="message"
                              rows="4"
                              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-rose-500 focus:border-rose-500 border-gray-300 rounded-md"
                              placeholder="Message"
                            />
                          </div>
                          <div>
                            <div className="flex items-start">
                              <div className="flex-shrink-0">
                                <Switch
                                  checked={enabled}
                                  onChange={setEnabled}
                                  className={`${
                                    enabled ? 'bg-rose-600' : 'bg-gray-200'
                                  } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500`}
                                >
                                  <span className="sr-only">Agree to policies</span>
                                  <span
                                    className={`${
                                      enabled ? 'translate-x-5' : 'translate-x-0'
                                    } inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                                  />
                                </Switch>
                              </div>
                              <div className="ml-3">
                                <p className="text-base text-gray-500">
                                  Dengan Memilih Ini, Anda Menyetujui {` `}
                                  <Link
                                    to="/kebijakan-privasi/"
                                    className="font-medium text-gray-700 underline"
                                  >
                                    Kebijakan Privasi
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div>
                            {enabled ? (
                              <button
                                type="submit"
                                onClick={() => setIsOpen(true)}
                                className="w-full inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-rose-500"
                              >
                                Submit
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="w-full inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-rose-500"
                                disabled
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </form>
                      )}
                    </Transition>
                    <Transition
                      show={isOpen}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      {(ref) => (
                        <div ref={ref} className={`${isOpen ? '' : 'hidden'}`}>
                          <div className="divide-y divide-gray-200">
                            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                              <p>
                                Aktifbola Situs Judi Online Terbaik Bagi Anda Yang Sangat Menyukai
                                Taruhan Judi Bola Dan Judi Slot Online Dengan Bonus Terbesar Seperti
                                :
                              </p>
                              <ul className="list-disc space-y-2">
                                <li className="flex items-start">
                                  <span className="h-6 flex items-center sm:h-7">
                                    <svg
                                      className="flex-shrink-0 h-5 w-5 text-cyan-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                  <p className="ml-2">
                                    Tournament Parlay , Berhadiah Puluhan Juta Setiap Bulannnya.
                                  </p>
                                </li>
                                <li className="flex items-start">
                                  <span className="h-6 flex items-center sm:h-7">
                                    <svg
                                      className="flex-shrink-0 h-5 w-5 text-cyan-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                  <p className="ml-2">
                                    Event Mix Parlay Lose 1 , Jackpot Parlay & Win Streak Parlay 3
                                    Tim.
                                  </p>
                                </li>
                                <li className="flex items-start">
                                  <span className="h-6 flex items-center sm:h-7">
                                    <svg
                                      className="flex-shrink-0 h-5 w-5 text-cyan-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                  <p className="ml-2">
                                    Extra Bonus Free Spin, Extra Bonus Joker Dan Extra Bonus Aztec
                                    Gems
                                  </p>
                                </li>
                              </ul>
                              <p>
                                <strong>Situs Judi Online</strong> Pilihan Yang Sempurna Untuk Anda
                                Yang Menginginkan Pengalaman Bermain Terbaik Dengan Layanan Pelangan
                                Terbaik
                              </p>
                            </div>
                            <div className="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                              <p>Ingin Promo Aktifbola Terlengkap ?</p>
                              <p>
                                <Link
                                  to="http://178.128.99.86/promo"
                                  className="text-rose-600 hover:text-rose-700"
                                >
                                  Selengkapnya&rarr;
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </Transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sites />
    </Layout>
  )
}

export default HubungiKami
